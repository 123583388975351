<template>
  <redness-template
    :skin-redness="skinRedness"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import RednessTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/redness/RednessTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const fieldsToResetOnNo = [
  'rednessLimitedToPimples',
  'rednessLocations',
  'rednessDiscomfortFrequency',
  'rednessDuration',
  'otherRednessLocations',
  'inflammationDiscomfort'
];

const fieldsToReset = ['facialSwelling'];

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  ...fieldsToResetOnNo.map(field),
  ...fieldsToReset.map(field),
  requiredField('skinRedness')
];

export default {
  name: 'Redness',
  components: {
    RednessTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    skinRedness(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToResetOnNo);
      }

      this.resetStoreFieldsByName(fieldsToReset);

      this.showNextStep();
    }
  }
};
</script>
